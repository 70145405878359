<template>
  <div class="lawRuleModels">
    <!-- 首页模型 -->
    <div class="models">
      <div class="inner">
        <!-- logo -->
        <img class="h-logo" src="@/assets/images/lawRuleModels/logo.png" />

        <!-- 文字标题 -->
        <div class="h-title">
          <div class="main">
            <img src="@/assets/images/lawRuleModels/title.png" />
          </div>
          <div class="desc">模型应用法治化、法治业务模型化</div>
        </div>

        <!-- 模型图悬浮文字 - 左边 -->
        <div class="m-left m-text-ani1 text1">
          <span>上下文学习</span>
        </div>
        <div class="m-left m-text-ani2 text2">
          <span>信息抽取</span>
        </div>
        <div class="m-left m-text-ani1 text3">
          <span>复杂推理</span>
        </div>
        <div class="m-left m-text-ani2 text4">
          <span>内容生成</span>
        </div>

        <!-- 模型图悬浮文字 - 右边 -->
        <div class="m-right m-text-ani1 text1">
          <span>摘要解析</span>
        </div>
        <div class="m-right m-text-ani1 text2">
          <span>知识检索</span>
        </div>
        <div class="m-right m-text-ani2 text3">
          <span>关联推荐</span>
        </div>
        <div class="m-right m-text-ani2 text4">
          <span>问答交互</span>
        </div>

        <!-- 介绍部分 -->
        <div class="introduce">
          <div class="item" v-for="(item, index) of centerIntroduceConfig" :key="item.title">
            <div class="left">
              <img :src="require(`@/assets/images/lawRuleModels/${item.imgPath}`)" />
            </div>

            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 功能部分 -->
    <div class="feature">
      <div class="inner">
        <div class="item" v-for="(item, index) of featureConfig" :key="item.name">
          <!-- 可以链接跳转 -->
          <template v-if="item.path">
            <a :href="item.path" target="_blank">
              <img :src="require(`@/assets/images/lawRuleModels/feature-${index + 1}.png`)" />
            </a>
          </template>

          <!-- 不可以链接跳转 -->
          <template v-else>
            <img :src="require(`@/assets/images/lawRuleModels/feature-${index + 1}.png`)" />
          </template>
        </div>
      </div>
    </div>

    <!-- 版权信息 -->
    <div class="copyright">©版权所有：北京北大软件工程股份有限公司</div>
  </div>
</template>

<script setup>
const centerIntroduceConfig = [
  {
    imgPath: 'center-1.png',
    title: '业务覆盖广',
    desc: '立法、执法、监督、复议全业务覆盖'
  },
  {
    imgPath: 'center-2.png',
    title: '领域认知深',
    desc: '深度加工领域知识，整理萃取领域特征'
  },
  {
    imgPath: 'center-3.png',
    title: '结果更准确',
    desc: '领域专家持续反馈，领域模型不断调优'
  },
  {
    imgPath: 'center-4.png',
    title: '服务更可控',
    desc: '可私有化部署，客户资源不出域'
  }
]

const featureConfig = [
  {
    name: '法治知识问答',
    path: '/fuyiTool/chat'
    // path: 'http://192.168.1.66:8111/#/fzzykLargeModel/chatContent'
  },
  {
    name: '立法辅助起草',
    path: 'http://192.168.3.184:9090/assist-draft'
  },
  {
    name: '智能版本对照',
    path: 'http://192.168.3.184:9090/version-compare-v2'
  },
  {
    name: '法规智能审查',
    path: 'http://192.168.3.184:9090/assist-examine'
  },
  {
    name: '书面意见汇总',
    path: 'http://192.168.3.184:9090/opinion-collect'
  },
  {
    name: '公开意见处理',
    path: 'http://192.168.3.184:9090/opinion-handle'
  },
  {
    name: '复议要素提取',
    path: '/fuyiTool/extraction'
    // path: 'http://192.168.1.66:8111/#/fzzykLargeModel/textContent'
  },
  {
    name: '智能报告生成',
    path: 'http://192.168.2.202:6012/index?#/special/index'
  },
  {
    name: '智能案卷评查',
    path: ''
  },
  {
    name: '复议风险预测',
    path: ''
  },
  {
    name: '执法类案推荐',
    path: ''
  },
  {
    name: '处罚结果推荐',
    path: ''
  }
]
</script>

<style scoped lang="less">
.lawRuleModels {
  width: 100%;
  background-color: white;

  position: relative;
  left: 0;
  top: 0;

  .models {
    height: 75vh;
    overflow: hidden;

    .inner {
      width: 100vw;
      height: 100vh;

      background: url('~@/assets/images/lawRuleModels/home-back.png') no-repeat;
      background-size: 100%;

      position: relative;
      left: 0;
      top: 0;

      .h-logo {
        display: inline-block;
        position: absolute;
        left: 12%;
        top: 40px;
      }

      .h-title {
        position: absolute;
        left: calc(11% + 10px);
        top: 17%;

        .desc {
          font-size: 22px;
          letter-spacing: 4px;
          color: #ffffff;
          margin-left: 10px;
          margin-top: 20px;
        }
      }

      // 模型文字动画效果
      .m-text-ani1 {
        animation-delay: 2000ms;
        animation: infinite 4000ms moveTextUp;
      }

      .m-text-ani2 {
        animation-delay: 2000ms;
        animation: infinite 4000ms moveTextDown;
      }

      @keyframes moveTextUp {
        0% {
          transform: translateY(0px);
        }

        35% {
          transform: translateY(-6px);
        }

        100% {
          transform: translateY(0px);
        }
      }

      @keyframes moveTextDown {
        0% {
          transform: translateY(0px);
        }

        35% {
          transform: translateY(6px);
        }

        100% {
          transform: translateY(0px);
        }
      }

      .m-left {
        width: 195px;
        height: 106px;

        background: url('~@/assets/images/lawRuleModels/models-left.png') no-repeat 100%;
        background-size: 100% 100%;

        font-size: 17px;
        color: white;

        position: absolute;

        &.text1 {
          left: 44%;
          top: 5%;

          span {
            display: inline-block;

            position: absolute;
            left: calc(26% + 2px);
            top: 36%;
          }
        }
        &.text2 {
          left: 41%;
          top: 14%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
        &.text3 {
          left: 40%;
          top: 23%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
        &.text4 {
          left: 39%;
          top: 33%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
      }

      .m-right {
        width: 195px;
        height: 106px;

        background: url('~@/assets/images/lawRuleModels/models-right.png') no-repeat 100%;
        background-size: 100% 100%;

        font-size: 17px;
        color: white;

        position: absolute;

        &.text1 {
          left: 77%;
          top: 5%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
        &.text2 {
          left: 79%;
          top: 14%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
        &.text3 {
          left: 80%;
          top: 23%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
        &.text4 {
          left: 82%;
          top: 33%;

          span {
            display: inline-block;

            position: absolute;
            left: 30%;
            top: 36%;
          }
        }
      }

      .introduce {
        width: 1300px;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        position: absolute;
        top: 57%;
        left: calc((100% - 1300px) / 2);
        // 鼠标移入放大效果

        .item {
          display: flex;
          margin: 0 25px;
          cursor: pointer;

          .left {
            height: 90px;

            &:hover {
              transition: transform 400ms ease;
              transform: scale(1.1);
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            width: 150px;
            margin-left: 15px;

            .title {
              font-size: 24px;
              font-weight: bold; // 待修改：部分浏览器看不到 font-weight 的细致宽度
              margin-bottom: 10px;
            }

            .desc {
              font-size: 16px;
              line-height: 23px;
              text-align: justify;
            }
          }
        }

        .item:last-child {
          .right {
            width: 120px;
          }
        }
      }
    }
  }

  .feature {
    width: 1250px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .inner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        margin: 15px;
        cursor: pointer;

        transition: transform 400ms ease, box-shadow 400ms ease;
        border-radius: 7px;

        a {
          display: block;
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          transform: scale(1.1);
          box-shadow: 0px 0px 10px 3px rgba(173, 181, 189, 0.4);
        }
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    margin-top: 100px;
    padding: 30px 0px;
    color: #95a5a6;
    font-size: 14px;
  }
}
</style>
